import React, { useState, useEffect } from 'react';
import './App.css';
import NavbarComponent from './components/NavbarComponent';
import CostCheckerPage from './pages/CostCheckerPage';
import PriceModifyerPage from './pages/PriceModifyerPage';
import Home from './pages/Home';
import { Routes, Route } from "react-router-dom"
import { Container } from "react-bootstrap"

function App() {
 
  return (
    <div className="App">
      <NavbarComponent />
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/cost" element={<CostCheckerPage getAPI="/api/product/getCosts"/>} />
      <Route path="/PriceModifyer" element={<PriceModifyerPage getAPI="/api/product/getCostPriceDetails"/>} />
      </Routes>
      
    </div>
  );
}

export default App;
