import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'

const NavbarComponent = () => {
    return (
        <Navbar bg="success" variant='dark' >
            <Container>
                <Navbar.Brand href="/home">
                    <img
                        src="/assets/images/logo.png"
                        alt='logo'
                        height='30'
                    />{' '}
                    <span className="d-none d-sm-none d-md-inline-block">Bel Toufic</span>
                </Navbar.Brand>

                <Nav className="justify-content-end">
                    <Nav.Item ><Nav.Link href="/">Home</Nav.Link></Nav.Item>
                    <Nav.Item ><Nav.Link href="/cost">Cost Checker</Nav.Link></Nav.Item>
                    <Nav.Item ><Nav.Link href="/PriceModifyer">Price Modifyer</Nav.Link></Nav.Item>
                </Nav>

            </Container>
        </Navbar>
    )
}

export default NavbarComponent
