import React from 'react'
import axios from 'axios'
import SearchableTable from '../components/SearchableTable';
import { Col, Card } from 'react-bootstrap'

const Home = (props) => {

    
    return (
        <>
            <Col sm={12} md={12} lg={8} className="my-2 mx-auto">
                
                        <div className="card-title text-center h3" style={{ "font-family": "Georgia" }}>Home</div>
                    
                            

            </Col >
        </>
    )
}

export default Home
