// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */

/* Reset some default styles for consistency */
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  /* Set styles for the top-level container (e.g., the header) */
  .App {
    text-align: center;
  }
  
  /* Style the main title */
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* Style the search input */
  input[type="text"] {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }
  
  /* Style the table */
  table {
    border-collapse: collapse;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Style table headers */
  th {
    background-color: #f2f2f2;
    font-weight: bold;
    padding: 10px;
    text-align: left;
  }
  
  /* Style table cells */
  td {
    padding: 10px;
    border: 1px solid #ddd;
  }
  
  /* Highlight table rows on hover */
  tr:hover {
    background-color: #f5f5f5;
  }
  `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;;AAEZ,8CAA8C;AAC9C;IACI,SAAS;IACT,UAAU;IACV;;gBAEY;IACZ,mCAAmC;IACnC,kCAAkC;EACpC;;EAEA,8DAA8D;EAC9D;IACE,kBAAkB;EACpB;;EAEA,yBAAyB;EACzB;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA,2BAA2B;EAC3B;IACE,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA,oBAAoB;EACpB;IACE,yBAAyB;IACzB,WAAW;IACX,gBAAgB;IAChB,cAAc;EAChB;;EAEA,wBAAwB;EACxB;IACE,yBAAyB;IACzB,iBAAiB;IACjB,aAAa;IACb,gBAAgB;EAClB;;EAEA,sBAAsB;EACtB;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA,kCAAkC;EAClC;IACE,yBAAyB;EAC3B","sourcesContent":["/* App.css */\n\n/* Reset some default styles for consistency */\nbody {\n    margin: 0;\n    padding: 0;\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n      sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n  }\n  \n  /* Set styles for the top-level container (e.g., the header) */\n  .App {\n    text-align: center;\n  }\n  \n  /* Style the main title */\n  h1 {\n    font-size: 2rem;\n    margin-bottom: 20px;\n  }\n  \n  /* Style the search input */\n  input[type=\"text\"] {\n    padding: 10px;\n    font-size: 1rem;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    width: 100%;\n    max-width: 300px;\n    margin-bottom: 20px;\n  }\n  \n  /* Style the table */\n  table {\n    border-collapse: collapse;\n    width: 100%;\n    max-width: 800px;\n    margin: 0 auto;\n  }\n  \n  /* Style table headers */\n  th {\n    background-color: #f2f2f2;\n    font-weight: bold;\n    padding: 10px;\n    text-align: left;\n  }\n  \n  /* Style table cells */\n  td {\n    padding: 10px;\n    border: 1px solid #ddd;\n  }\n  \n  /* Highlight table rows on hover */\n  tr:hover {\n    background-color: #f5f5f5;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
